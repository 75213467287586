// 0-600px: Phone
// 600-900px: Tablet Portrait
// 900-1200px: Tablet Landscape
// 1200-1800px: Desktop
// 1800+ : Large Monitors
@mixin respond($breakpoint) {
  @if $breakpoint==phone {
    @media (max-width: 26.5625em) {
      //425px
      @content;
    }
  }
  @if $breakpoint==tablet {
    @media (max-width: 48em) {
      //768px
      @content;
    }
  }
  @if $breakpoint==laptop {
    @media (max-width: 64em) {
      // 1024px
      @content;
    }
  }
  @if $breakpoint==desktop {
    @media (max-width: 90em) {
      // 1440px
      @content;
    }
  }
  @if $breakpoint==huge {
    @media (max-width: 160em) {
      //2560
      @content;
    }
  }
}
