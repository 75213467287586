.loading {
  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.3em solid rgba(218, 219, 223, 1);
  border-left: 0.3em solid rgb(51, 153, 255);
  animation: spin 0.7s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}
