.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background: linear-gradient(to right, $grey-1, $grey-2);
  box-shadow: $shadow;

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-grow: 2;
    @include respond(tablet) {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
    transition: all 0.2s;
    border-right: 1px solid $grey-3;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: $text-body;
    height: 100%;
    width: 100%;
    text-align: center;

    &:last-child {
      border: none;
    }

    &-active {
      color: $grey-4;
    }
  }

  &__linkhead {
    text-decoration: none;
    transition: all 0.2s;
    border:none;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    color: $text-body;
    height: 100%;
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: large;

    &-active {
      color: $primary;
    }
  }

  &__heading {
    flex-grow: 1;
    align-items: center;
  }

  &__img {
    height : 40px;
    width: 100%;
  }
}

.nav__search {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding-right: 1rem;

  @include respond(tablet) {
    display: none;
  }

  input {
    height: 30px;
    border-radius: 5px;
    border: 2px solid white;
    outline: none;
    transition: all 0.3s;
    width: 70%;
    height: 42px;
    padding-left: 0.5rem;
    margin-right: 0.3rem;
    border: 1.5px solid $grey-2;

    &:focus {
      border-color: $primary;
    }
  }

  button {
    background: $primary;
    border-radius: 5px;
    border: none;
    transition: all 0.2s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    color: white;

    &:hover {
      background: $primary-darker;
    }
  }
}

.mobile-btn {
  transition: all .2s;
  display: none;
  align-items: center;
  justify-content: center;
  background: $grey-3;
  border: 2px solid $grey-4;
  color: $white;
  border: none;
  width: 34px;
  height: 34px;
  font-size: 17px;
  border-radius: 10px;
  position: fixed;
  right: 10px;
  top: 12px;
  cursor: pointer;
  outline: none;

  @include respond(tablet) {
    display: flex;
  }

  &:hover {
    background: $grey-4;
    color: $primary;
  }
}

.nav-drawer {
  transition: all 0.3s;
  transition-timing-function: ease-in-out;
  top: 55px;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: $grey-1;
  z-index: -4;

  &-show {
    opacity: 1;
    display: block;
  }

  &-hide {
    opacity: 0;
    transform: translateX(-1150px);
    display: none;
    z-index: -3;
  }
}

.search-container-sm {
  width: 80%;
  margin: auto;
  margin-top: 20px;

  input {
    height: 30px;
    width: 100%;
    color: rgb(80, 80, 80);
    padding: 10px;
    font-size: 17px;
    border-radius: 5px;
    border: 2px solid rgb(220, 220, 220);
    outline: none;
    margin-bottom: 10px;
    transition: all 0.2s;
    text-align: center;

    &:focus {
      border: 2px solid rgb(51, 153, 255);
    }
  }

  button {
    display: block;
    outline: none;
    border: 1px solid rgb(50, 50, 50);
    background: inherit;
    height: 35px;
    width: 100%;
    border-radius: 10px;
    font-size: 20px;
    color: rgb(50, 50, 50);
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      color: white;
      background: rgb(51, 153, 255);
      border-color: rgb(51, 153, 255);
    }
  }
}

.drawer-links {
  display: block;
  padding: 20px;
  max-width: 85%;
  margin: auto;
  border-radius: 4px;
}

.drawer-link {
  border-radius: 4px;
  color: rgb(80, 80, 80);
  text-decoration: none;
  display: block;
  padding: 20px;
  border: 1px solid rgb(50, 50, 50);
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.drawer-link:hover {
  background: rgb(50, 50, 50);
  color: white;
}

@media only screen and (max-width: 1050px) {
  .heading {
    font-size: 15px;
  }
  .nav {
    grid-template-columns: 1fr;
  }

  .nav-link {
    display: none;
  }

  .search-container {
    display: none;
  }

  .nav-heading {
    padding-top: 20px;
    font-size: 20px;
  }

  .nav-drawer {
    display: block;
  }
}
