.source-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &-element {
    color: black;
    text-decoration: none;
    height: 140px;
    width: 100px;
    transition: transform 0.2s;
    margin: 2rem;

    img {
      height: 100px;
      width: 100px;
    }
    p {
      text-align: center;
    }
  }
}

.source-filter {
  margin-top: 30px;
  max-height: 500px;
  overflow-y: scroll;
  list-style-type: none;
  margin-bottom: 50px;

  &__item {
    font-size: 22px;
    border-bottom: 1px solid rgb(200, 200, 200);
    cursor: pointer;
    background: rgb(250, 250, 250);

    &:hover {
      border-color: rgb(51, 153, 255);
    }
  }

  &__link {
    display: block;
    padding: 15px;
    color: black;
    text-decoration: none;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 850px) {
  .source-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .source-search {
    width: 90%;
    margin: auto;
  }
}

@media only screen and (max-width: 650px) {
  .source-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (max-width: 500px) {
  .source-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .source-search {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .source-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .source-grid-element {
    height: 200px;
    width: 160px;
  }

  .source-grid-element img {
    height: 160px;
    width: 160px;
  }
}
