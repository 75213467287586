.input {
  height: 30px;
  border-radius: 5px;
  border: 2px solid white;
  outline: none;
  transition: all 0.3s;
  width: 100%;
  height: 42px;
  padding-left: 0.5rem;
  margin-right: 0.3rem;
  border: 1.5px solid $grey-2;

  &:focus {
    border-color: $primary;
  }
}
