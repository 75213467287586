// $text-heading: #EAE7DC;
// $text-body: #D1E8E2;
$text-heading: #111111;
$text-body: #313131;

// $primary: #E8584F;
$primary: #313131;
$primary-darker: #265fa1;
$secondary: #00bfb2;
$white: #f9fdff;

// $grey-1: #8E8D8A;
// $grey-2: #D8C3A5;
$grey-1: #FFFFFF;
$grey-2: #FFFFFF;
$grey-3: rgb(200, 200, 200);
$grey-4: rgb(180, 180, 180);

$shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.27);

$panel-color: #FFFFFF;
$panel-hover: #F2F2F2;
// $background: #EAE7DC;
// $border-shade: #D1E8E2;
$background: #FFFFFF;
// $border-shade: #111111;
// $background: #272727;
$border-shade: #111111;
