h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1rem;
}

h4 {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.85rem;
}

h5 {
  font-size: 1.05rem;
}

h1,
h2,
h3,
h4 {
  color: $text-heading;
}

p,
li,
a,
button,
input,
select,
option,
textarea,
label,
span {
  font-size: 0.85rem;
  white-space: pre-wrap;
  color: $text-body;
  font-weight: 400;
  line-height: 1rem;
}

a {
  text-decoration: none;
  color: $primary
}
