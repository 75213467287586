.article-container {
  max-width: 100%;
  padding: 1rem;
  margin: auto;
  margin-top: 12vh;
  list-style-type: none;
  column-count: 1;
  column-gap: 1rem;
  column-fill: auto;
  background-color: $background;
}

.article {
  break-inside: avoid;
  display: flex;
  background: $panel-color;
  border-radius: 1rem;
  border-color: $border-shade;
  border-width: 0.001vw;
  border: 1px solid $grey-3;
  margin-bottom: 0.5vh;
  margin-left: 3vw;
  margin-right: 3vw;
  padding: 0.3vw;
  text-decoration: none;
  color: black;
  box-shadow: $shadow;
  max-width: 100%;
  &__content {
    padding: 1rem;
    width: 100%;
    font-size: 1vw;
    font-weight: 300;
    line-height: 25px;
    object-fit: contain;
    border-radius: 0.7rem;
  }
  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
  }
  &:hover {
    background: $panel-hover;
    transition: all 0.2s;
    animation-direction: normal;
  }
  img {
    width: 20%;
    height: 120px;
    border-radius: 10px 10px 10px 10px;
    object-fit: contain;
  }
}